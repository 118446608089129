var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.role ? 'container_is_auth' : 'container_no_auth'},[(!_vm.role)?_c('Nav'):_vm._e(),(!_vm.loading)?_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[(_vm.surveys)?_c('div',_vm._l((_vm.surveys.data),function(survey,idx){return _c('div',{key:idx},[_c('div',[_c('v-card',{staticClass:"radius-card",attrs:{"elevation":"0"}},[_c('div',{class:survey.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : survey.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__',staticStyle:{"height":"15px","width":"100%"}}),_c('div',{staticClass:"pa-5 mb-5"},[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(survey.judul))]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(survey.keterangan))])])]),_c('v-card',{staticClass:"radius-card py-3 px-5 mb-3",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',{staticClass:"mt-2"},[_vm._v("Nama lengkap")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Nama Lengkap","dense":""},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})],1)],1)],1),_vm._l((survey.relationships),function(relationship,idx){return _c('div',{key:("xX" + idx + "Xx")},[(relationship.soal)?_c('div',_vm._l((relationship.soal.data),function(question){return _c('v-card',{key:("quest-" + (question.id)),staticClass:"radius-card px-5 pt-5 mb-5",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('h3',[_vm._v(_vm._s(question.soal))])])],1),_c('div',{staticClass:"py-3"},[(question.tipe == 'single')?_c('section',[_c('v-radio-group',_vm._l((question.pilihan),function(val,key){return _c('v-radio',{key:key,attrs:{"label":val,"value":key,"id":("radio-" + (question.id) + "-" + key)},on:{"click":function($event){return _vm.getAnswer(question.id, key)}}})}),1)],1):_vm._e(),(question.tipe == 'multiple')?_c('section',{staticClass:"my-3"},_vm._l((question.pilihan),function(val,key){return _c('v-checkbox',{key:("multi" + key),staticClass:"ma-1",attrs:{"label":val,"hide-details":"","value":val}})}),1):_vm._e()])],1)}),1):_vm._e()])}),_c('div',[(!_vm.loadSave)?_c('v-btn',{attrs:{"depressed":"","color":survey.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : survey.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__',"dark":""},on:{"click":_vm.saveAnswer}},[_c('b',[_vm._v("Kirim")])]):_vm._e(),(_vm.loadSave)?_c('v-btn',{attrs:{"depressed":"","color":survey.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : survey.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__',"dark":""}},[_c('b',[_vm._v("Loading..")])]):_vm._e()],1)],2)])}),0):_vm._e()]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},[_c('h3',[_vm._v("Loading...")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }